<template>
  <div class="eventlog">
    <v-col cols="12" sm-11>
      <v-card>
        <v-card-title>
          <h3>EventLog - {{ pumpcname }}</h3>
          <v-spacer></v-spacer>
          <v-pagination v-model="options.page" :length="numpages" :total-visible="9"></v-pagination>
        </v-card-title>
        <v-card-subtitle>
          {{ pumpnname }} ({{ pumpip }}) - {{ new Date(last_check_time).toLocaleTimeString("en-US", { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }) }} AEDT
        </v-card-subtitle>
        <v-data-table :headers="headers" :items="eventslist" 
        :options.sync="options" :server-items-length="totalevents" :loading="loading"
        :footer-props="{'items-per-page-options':[30, 100, 250]}" :items-per-page="30" dense>
          <template v-slot:top="{ pagination, options, updateOptions }">
            <v-data-footer 
              :pagination="pagination" 
              :options="options" @update:options="updateOptions"
              :items-per-page-options="[30, 100, 250]" :items-per-page="30"
              items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
          </template>
          <template #item.date="{ item }">
            {{ new Date(item.timestamp.split(" ")[0]).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }) }}
          </template>
          <template #item.time="{ item }">
            {{ item.timestamp.split(" ")[1] }}
          </template>
          <template #item.eventmsg="{ item }">
            <!--template v-if="item.eventtype==1">
              <div v-for="dtc,idx in item.dtclist" :key="dtc.shortLabel">
                <span><a :href="`dtc/${dtc.shortLabel}`" >{{ dtc.shortLabel }}</a> &nbsp; {{ dtc.label }}</span>
                <v-divider v-if="idx!=item.dtclist.length-1"></v-divider>
              </div>
            </template-->
            <v-container v-if="item.eventtype==1">
              <v-row v-for="dtc,idx in item.dtclist" :key="dtc.shortLabel" dense>
                <v-col cols="1"><v-chip :color="levelColor(dtc.shortLabel)">{{ levelText(dtc.shortLabel) }}</v-chip></v-col>
                <v-col cols="1"><RunState :runstate="calcRunState(dtc.shortLabel)" /></v-col>
                <v-col :class="(idx!=item.dtclist.length-1)?'low-border':''">
                  <a :href="`dtc/${dtc.shortLabel}`" >{{ dtc.shortLabel }}</a> - {{ dtc.label }}
                </v-col>
              </v-row>
            </v-container>
            <span v-else>{{ item.message }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </div>
</template>

<script>
  import RunState from "../components/RunState.vue"
  import colors from 'vuetify/lib/util/colors';
  export default {
    name: 'PumpEventLog',
    components:{
      RunState
    },
    data () {
      return {
        search: '',
        loading: true,
        options: {},
        headers: [
          { text: 'Date', value: 'date', sortable: false, filterable:false },
          { text: 'Time', value: 'time', sortable: false, filterable:false },
          //{ text: 'Type', value: 'eventtype', sortable: false, filterable:false },
          { text: 'Event', value: 'eventmsg', sortable: false, filterable:false }
        ],
        pumpip:"",
        pumpcname:"",
        pumpnname:"",
        eventslist: [],
        totalevents: 0,
        numpages: 0,
        last_check_time:""
      }
    },
    watch: {
      options: {
        handler () {
          this.getDataFromApi();
          this.numpages = Math.ceil(this.totalevents/this.options.itemsPerPage);
        },
        deep: true,
      },
    },
    methods: {
      getDataFromApi () {
        const { page, itemsPerPage } = this.options
        this.loading = true;
        this.$api.get(this.$dtcdburl + "/events/view?ip=" + this.$route.query["pumpip"] 
                      + "&limit="+itemsPerPage + "&offset="+((page-1)*itemsPerPage))
        .then(response => {
          if (response.data["code"] == "get_pump_events") {
            this.pumpip = response.data["pumpip"];
            this.pumpcname = response.data["cname"];
            this.pumpnname = response.data["nname"];
            this.eventslist = response.data["events"];
            //this.totalevents = response.data["total_events_count"];
            this.last_check_time = response.data["lastcheck"];
            // Set the initial number of items
            //console.log(this.dtc_list);
            //this.$emit('loadcomplete');
          }
          this.loading = false;
        });
      },
      calcRunState: function (shortLabel) {
        var runstate = 0;
        console.log(shortLabel)
        switch(shortLabel) {
          // These are the various starting codes
          case "I00013": // Pump start from vhmi
          case "I00012": // Pump start from Remote HMI
          case "I00011": // Pump start from hmi
          case "I00010": // Pump start from MCD
            runstate = 1;//Starting
            break;
          // These are the various STOPPING codes
          case "I00001":  // Pump stopped due to aggregated error
            runstate = 3;
            break;
          case "I00008":  // Pump stopped due to Idle Shutdown                                            
          case "I00096":  // Pump stopped due to HMI Enable deasserted 
          case "E00030":  // Pump stopped due to internal ESTOP  // This is new in latest software - specifically to help with TVMM reporting; Was aggregated error                        
          case "E00031":  // Pump stopped due to External ESTOP       
          case "I00095":  // Pump stopped due to remote enable deasserted
          case "I00097":  // Pump stopped due to mcd enable deasserted
          //case 10008:  // Pump stopped due to ESTOP
            runstate = 0;//
            break;
          default:
            if (shortLabel.charAt(0) == "I")
              runstate = 2;
            else runstate = -1;
            break;
            // code block
        }
        return runstate;
      },
      levelText: function (shortLabel) {
        var severity = "";
        switch(shortLabel.charAt(0)) {
          case "I":
            severity = "INFO";
            break;
          case "W":
            severity = "WARN";
            break;
          case "E":
            severity = "ERROR";
            break;
          default:
            severity = "null";
            break;
            // code block
        }
        return severity;
      },
      levelColor: function (shortLabel) {
        var severity = "";
        switch(shortLabel.charAt(0)) {
          case "I":
            severity = colors.blue.lighten4;
            break;
          case "W":
            severity = "yellow";
            break;
          case "E":
            severity = "red";
            break;
          default:
            severity = "white";
            break;
            // code block
        }
        return severity;
      },
    },
    mounted(){
      //this.getDataFromApi();
      //this.$emit('loadstart');
      this.$api.get(this.$dtcdburl + "/events/count?ip=" + this.$route.query["pumpip"])
      .then(response => {
        //this.eventslist = response.data;
        this.totalevents = response.data;
        this.numpages = Math.ceil(this.totalevents/this.options.itemsPerPage);
        //console.log(this.dtc_list);
        // Set the initial number of items
        //this.totalRows = this.bill_list.length;
        //this.$emit('loadcomplete');
      });
    },
  }
</script>
<style scoped>
td.text-start > tr {
  padding: 10px;
  margin: 100px;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
td > a {
  height: 60px;  
}      
.low-border {
  border-bottom: 1px solid lightgray;
}
</style>