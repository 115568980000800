<template>
  <div class="sitereport">
    <v-col cols="12" sm-11>
      <v-card>
        <v-card-title>
          <h3>SiteReport - {{ this.$route.query["sitename"] }} </h3>
        </v-card-title>
        <v-card-subtitle>
          Total {{ site_pumps_list.length }} pumps
        </v-card-subtitle>
        <v-container>
          <v-row>
            <v-col><h3>Events Histogram</h3></v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="4">
              <v-menu v-model="startdatemenu" :close-on-content-click="false" :nudge-right="40" 
                transition="scale-transition" offset-y min-width="auto" >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="startdate" readonly v-bind="attrs" v-on="on"
                    label="Select Start Date" prepend-icon="mdi-calendar"         
                  ></v-text-field>
                </template>
                <v-date-picker v-model="startdate" @input="startdatemenu = false" ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" md="4">
              <v-menu v-model="enddatemenu" :close-on-content-click="false" :nudge-right="40" 
                transition="scale-transition" offset-y min-width="auto" >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="enddate" readonly v-bind="attrs" v-on="on"
                    label="Select End Date" prepend-icon="mdi-calendar"         
                  ></v-text-field>
                </template>
                <v-date-picker v-model="enddate" @input="enddatemenu = false" ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2" md="1">
              <v-text-field label="Limit" variant="solo" v-model="limit"></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn color="primary" @click.prevent="refresh_eventshist(siteid)">Refresh</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <apexchart height="600" type="bar" :options="eventhistoptions" :series="eventhistseries" @click="reload_pumpip_eventshist_table"></apexchart>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="2">
              <v-checkbox v-model="showerrors" label="Show Errors" color="red"></v-checkbox>
            </v-col>
            <v-col md="2">
              <v-checkbox v-model="showwarns" label="Show Warnings" color="orange"></v-checkbox>
            </v-col>
            <v-col md="2">
              <v-checkbox v-model="showinfos" label="Show Infos" color="blue"></v-checkbox>
            </v-col>
          </v-row>
          <v-row v-if="selected_dtc_summary.length >= 4">
            <v-col>
              <h3>{{ selected_dtc_summary[0] }} - {{ selected_dtc_summary[2] }} ({{ selected_dtc_summary[3].length }} pumps) </h3>

              <v-simple-table>
                <thead>
                  <tr>
                    <td><b>Id</b></td>
                    <td><b>Customer</b></td>
                    <td><b>Nickname</b></td>
                    <td class="text-center"><b>Pump IP</b></td>
                    <td class="text-right"><b>OccurrenceCount</b></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="iphist,idx in selected_dtc_summary[3]" :key="idx" >
                    <td>{{ idx+1 }}</td>
                    <td>{{ iphist[2] }} </td>
                    <td>{{ iphist[3] }} </td>
                    <!--td>{{ iphist[0] }} </td-->
                    <td class="text-center">
                      <v-btn x-small color="primary" @click="goto_eventssummary_page(iphist[0])" height="30px">
                        <v-icon > mdi-alpha-s-circle-outline </v-icon>
                      </v-btn>
                      <span class="smart-collapse">&nbsp;</span>
                      <a class="smart-collapse" :href="`events?pumpip=${iphist[0]}`">{{ iphist[0] }}</a>
                      <template v-if="view_health_link">
                        <v-btn x-small color="primary" @click="goto_pumphealth_page(iphist[0])" height="30px">
                          <v-icon > mdi-alpha-h-circle-outline </v-icon>
                        </v-btn>
                      </template>
                      <a class="smart-collapse" :href="`events?pumpip=${iphist[0]}`">{{ iphist[0] }}</a>
                    </td>
                    <td class="text-right">{{ iphist[1] }} </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </div>
</template>

<script>

  export default {
    name: 'EventSiteReport',
    data () {
      return {
        loading: true,
        //sitename: null,
        eventhistoptions: {
            /*xaxis: {
              categories: []
            },*/
            plotOptions: {
              bar: {
                //distributed: true, // for different colored bars
                horizontal: true
              }
            },
            colors: ["#4747FF"],
            fill: {
              type: "gradient",
              gradient: {
                  shade: "dark", type: "horizontal",
                  gradientToColors: ["#000077"], stops: [0, 100]
              }
            },
            stroke: { lineCap: "butt" },
            /*labels: ["Output Pressure"]*/
            tooltip: {
              custom: function({series, seriesIndex, dataPointIndex, w}) {
                var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                return '<div class="arrow_box">' +              
                  '<span>' + data.label + '</span>' +
                  ' : <span>' + series[seriesIndex][dataPointIndex] + '</span>' +    
                  '</div>'
              }
            },
            /*chart:{
              events:{
                //dataPointSelection: function(event, chartContext, config) {
                click: function(event, chartContext, config) {
                  console.log(chartContext);
                  //console.log(config.dataPointIndex);
                  let idx = config.dataPointIndex;
                  this.selected_dtc_idx = idx;
                  //this.reload_pumpip_eventshist_table(idx);
                  //handleClick(idx);
                }
              }
            }*/
        },
        showerrors: true, showwarns: true, showinfos: true, 
        
        site_pumps_list:[],
        summaryhist: [],
        eventhistseries: [{data: []}],

        startdatemenu: false,
        startdate: null,
        enddatemenu: false,
        enddate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        limit: 10,
        
        selected_dtc_summary:[]
      }
    },
    watch: {
      showerrors() { this.reload_eventshist_graph(); },
      showwarns() { this.reload_eventshist_graph(); },
      showinfos() { this.reload_eventshist_graph(); },
      eventhistoptions:{
        deep: true
      }
    },
    computed: {
      view_health_link(){
        const useraccessdict = this.$store.getters.get_useraccessdict();
        return (useraccessdict != null) && useraccessdict["addpumps"];
      }
    },
    methods: {
      refresh_eventshist(siteid){
        //this.$emit('loadstart');
        var event_summ_url = this.$dtcdburl + "/events/sitereport?siteid=" + siteid + "&limit="+this.limit;
        event_summ_url += "&sdate="+this.startdate + "&edate="+this.enddate;
        this.$api.get( event_summ_url )
        .then(response => {
            if (response.data["code"] == "get_site_report") {
              this.siteid = response.data["siteid"];
              //this.sitename = response.data["sitename"];
              this.site_pumps_list = response.data["company-pumps-list"];
              this.summaryhist = response.data['eventshist'];
              this.reload_eventshist_graph();
            }
            //this.$emit('loadcomplete');
        });
      },
      reload_eventshist_graph() {
        var chart_eventhist_list  = this.summaryhist.map( function (x) {
          return ({ 'x': x[0], 'y': x[1], 'label': x[0]+' - '+x[2] });
        });
        if (!this.showerrors) 
          chart_eventhist_list = chart_eventhist_list.filter( (d) => d['x'][0] != 'E');
        if (!this.showwarns) 
          chart_eventhist_list = chart_eventhist_list.filter( (d) => d['x'][0] != 'W');
        if (!this.showinfos) 
          chart_eventhist_list = chart_eventhist_list.filter( (d) => d['x'][0] != 'I');
        this.eventhistseries = [{ name: 'event count', data:  chart_eventhist_list }];

        /*this.eventhistoptions.colors = this.chart_eventhist_list.map( function (d) {
          if (d['x'][0] == 'E') return "#FF0000";
          else if (d['x'][0] == 'W') return "#FFFF47";
          else if (d['x'][0] == 'I') return "#4747FF";
          else return "#000000";
        });
        this.eventhistoptions.fill.colors = this.eventhistoptions.colors;*/
      },
      reload_pumpip_eventshist_table(event, chartContext, config){
        //console.log(event, chartContext, config);
        if (config.dataPointIndex == -1) return; // Click on chart but not on bargraph
        
        //var idx = config.dataPointIndex;
        var selectedShortLabel = this.eventhistseries[0]["data"][config.dataPointIndex]["x"];
        var idx = this.summaryhist.map(e => e[0]).indexOf(selectedShortLabel);
        //console.log(this.summaryhist[idx]);
        //this.selected_dtc_summary = this.summaryhist[idx];

        let pumpip_occurence_tpllist = this.summaryhist[idx][3];
        var ip_events_details = []
        for (var i=0; i < pumpip_occurence_tpllist.length; i++){
          let pumpip_occurence_tuple = pumpip_occurence_tpllist[i]
          var pumpip = pumpip_occurence_tuple[0];
          //console.log(pumpip);
          for (var j=0; j < this.site_pumps_list.length; j++){
            if (this.site_pumps_list[j]["ip"] == pumpip) {
              //console.log(this.site_pumps_list[j]);
              ip_events_details.push(
                [
                  this.site_pumps_list[j]["ip"],
                  pumpip_occurence_tuple[1],
                  this.site_pumps_list[j]["cname"],
                  this.site_pumps_list[j]["nname"]
                ]
              );
              break;
            }
          }
        }
        //console.log(ip_events_details);
        var dtc_summary_for_idx = this.summaryhist[idx].slice(0,3);
        //console.log(dtc_summary_for_idx);
        dtc_summary_for_idx.push(ip_events_details);
        //console.log(dtc_summary_for_idx);
        this.selected_dtc_summary = dtc_summary_for_idx;
      },
      goto_eventssummary_page(ipaddr) {
        //console.log("goto_eventssummary_page", ipaddr);
        this.$router.push({ path: '/eventsum', query: { "pumpip": ipaddr } });
      },
      goto_pumphealth_page(ipaddr) {
        //console.log("goto_eventssummary_page", ipaddr);
        this.$router.push({ path: '/pumphealth', query: { "pumpip": ipaddr } });
      },
    },
    mounted(){
      //const today = new Date();
      const start_of_month = new Date();
      start_of_month.setDate(1);      
      this.startdate = (new Date(start_of_month - start_of_month.getTimezoneOffset() * 60000)).toISOString().substr(0, 10);

      const siteid = this.$route.query["siteid"];
      this.refresh_eventshist(siteid);
    },
    
  }
</script>
<style scoped>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0px;
}
@media (min-width:960px){
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td>a.smart-collapse:nth-of-type(1) {
    display:inline-block;
    width:8em;
  }
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td>a.smart-collapse:nth-of-type(2) {
    display:none;
  }
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td>span.smart-collapse {
    display:none;
  }
}
@media (max-width:960px){
  .auto-collapse{
    display:none;
  }
}
@media (max-width:960px){
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td>a.smart-collapse:nth-of-type(1) {
    display:none;
  }
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td>a.smart-collapse:nth-of-type(2) {
    display:inline-block;
    width:8em;
  }
}
</style>