<template>
<div id="pumpspage" style="height:87vh">    
    <v-container fluid class="fill-height">
    <v-row class="fill-height" justify="space-between" >
      <v-col class="d-none d-sm-block fill-height" cols="12" sm="4" md="3">
        <v-card class="fill-height"  style="overflow-y: scroll">
          <v-card-title>
            <h3>Organisation</h3>
          </v-card-title>
          <MyTreeView @ouclicked="fetch_pumps_details" @datadropped="onPumpDrop"/>
        </v-card>
      </v-col>

      <v-col cols="12" sm="8" md="9" class="text-center fill-height">
        <v-card class="fill-height" v-if="datafetch_in_progress" cols="9">
          <v-row class="fill-height d-flex justify-center">
            <v-col cols="6">
              <LoadingBar />
            </v-col>
          </v-row>
        </v-card>
        <v-card class="fill-height" v-else-if="selectedNode !== null" cols="9" style="overflow-y: scroll">
        <v-card-title>
          <h3>{{ selectedNode.name }} - Pumps ({{ pumpslist.length }})</h3>
          <v-spacer></v-spacer>
          <v-btn v-if="view_health_link" @click="email_health_report(selectedNode.id)">
            <v-icon > mdi-alpha-e-circle-outline </v-icon>
          </v-btn>
          <div class="d-flex justify-space-around pr-6">
            <v-btn-toggle v-model="isRecursiveFetch" color="black">
              <v-btn :value="true">All</v-btn>
              <v-btn :value="false">Child</v-btn>
            </v-btn-toggle>
          </div>
          <span>&nbsp;</span>
          <v-dialog v-if="add_pumps_permission" v-model="addPumpDialog" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Add Pump
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Add New Pump</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Customer*" v-model="addPumpData.cname" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Nickname*" v-model="addPumpData.nname" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field label="IPAddress*" v-model="addPumpData.ip" required></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-text> {{ addpump_error_msg }} </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="addPumpClose()"> Close </v-btn>
                <v-btn color="blue darken-1" text @click="addPumpConfirm"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-dialog v-model="renamePumpDialog" width="500">
          <v-card>
              <v-card-title>
                <span class="text-h5">Rename Pump</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Customer*" v-model="renPumpData.cname" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Nickname*" v-model="renPumpData.nname" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field label="IPAddress*" v-model="renPumpData.ip" disabled></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-text> {{ renpump_error_msg }} </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="renamePumpClose()"> Close </v-btn>
                <v-btn color="blue darken-1" text @click="renamePumpConfirm"> Save </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>  
        <v-dialog v-model="deletePumpDialog" width="500">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2"> Confirm Deletion </v-card-title>
            <v-card-text> Are you sure you want to delete {{ seletectedDelPump["nname"] }} ? </v-card-text>
            <v-card-text> {{ delpump_error_msg }} </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="deletePumpConfirm" > Delete </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> 
        <v-dialog v-model="movePumptoOUDialog" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2"> Confirm Move </v-card-title>
                <v-card-text> Are you sure you want to move "{{ selectedPumptoMove["name"] }}" to "{{ selectedPumptoMove["newsitename"] }}"? </v-card-text>
                <v-card-text> {{ movePumptoOU_error_msg }} </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="moveConfirmClick" > Move </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>  
        <!--v-scroll-y-transition mode="out-in"-->
            <v-simple-table><!--fixed-header height="63vh"-->
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center auto-collapse" style="width:5%"> Move Pump </th>
                    <th class="text-center" style="width:5%"> Add to VHMI </th>
                    <th class="text-center" style="width:5%"> Add to Reporter </th>
                    <th class="text-left auto-collapse" style="width:15%"> Customer </th>
                    <th class="text-left"> Nickname (Edit)</th>
                    <th class="text-center" style="width:30%"> IP Address (Eventlog) </th>
                    <!--th class="text-left"> ID </th-->
                    <th class="text-center" style="width:5%" v-if="pumpslist.length==site_health_list.length"> Status </th>
                    <th class="text-center" style="width:5%"> Delete </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="pump,idx in pumpslist" :key="idx" >
                    <td class="auto-collapse">
                      <v-btn dark small color="green" draggable @dragstart="startDrag($event, pump.id, pump.nname)">
                        <v-icon dark> mdi-chevron-triple-left </v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn v-if="pump.onvhmi" dark small color="error">
                        <v-icon light > mdi-checkbox-marked-outline </v-icon>
                      </v-btn>
                      <v-btn v-else dark small color="primary" @click="add_pump_to_vhmi(pump.id,pump.ip)">
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn v-if="pump.onreporter" dark small color="error" @click="del_pump_from_reporter(pump.ip)">
                        <v-icon light > mdi-minus </v-icon>
                      </v-btn>
                      <v-btn v-else dark small color="primary" @click="add_pump_to_reporter(pump.id,pump.ip)">
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </td>
                    <td class="text-left auto-collapse">{{ pump.cname }}</td>
                    <td class="text-left"><a href="#" @click="renamePumpClick(idx)">{{ pump.nname }}</a></td>
                    <td class="text-center">
                      <v-btn x-small color="primary" @click="goto_eventssummary_page(pump.ip)" height="30px">
                        <v-icon > mdi-alpha-s-circle-outline </v-icon>
                      </v-btn>
                      <span class="smart-collapse">&nbsp;</span>
                      <a class="smart-collapse" :href="`events?pumpip=${pump.ip}`">{{ pump.ip }}</a>
                      <template v-if="view_health_link">
                        <v-btn x-small color="primary" @click="goto_pumphealth_page(pump.ip)" height="30px">
                          <v-icon > mdi-alpha-h-circle-outline </v-icon>
                        </v-btn>
                      </template>
                      <a class="smart-collapse" :href="`events?pumpip=${pump.ip}`">{{ pump.ip }}</a>
                    </td>
                    <!--td class="text-left">{{ pump.id }}</td-->
                    <td class="text-center" v-if="pumpslist.length == site_health_list.length">
                      <v-chip v-bind:color="(site_health_list[idx]['off'])?'orange':'white'">
                        {{ site_health_list[idx]['en'] }}/{{ site_health_list[idx]['inst'] }}
                      </v-chip>
                    </td>
                    <td class="text-center">
                      <v-btn dark small color="error" @click="deletePumpClick(idx)">
                        <v-icon dark> mdi-minus </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        <!--/v-scroll-y-transition-->
  </v-card>
      </v-col>
    </v-row>
    </v-container>
</div>
</template>

<script>
  import pumphooks from '../plugins/pump_data_hooks';
  import MyTreeView from "../components/MyTreeView";
  import LoadingBar from "../components/LoadingBar";

  export default {
    name: "PumpsPage",

  components: {
    MyTreeView,
    LoadingBar
  },
    data: () => ({
      datafetch_in_progress: false,
      selectedNode: null,
      isRecursiveFetch: false,

      addPumpDialog: false,
      addPumpData: {"ip": "", "nname":"", "cname":""},
      addpump_error_msg: null,

      renamePumpDialog: false,
      renPumpData: {"id": null, "ip": "", "nname":"", "cname":""},
      renPumpdx: -1,
      renpump_error_msg: null,

      deletePumpDialog: false,
      deletePumpdx: -1,
      seletectedDelPump: {},
      delpump_error_msg: null,

      movePumptoOUDialog: false,
      movePumptoOU_error_msg: null,
      selectedPumptoMove: {"id":null, "name": null, 'newsiteid': null, 'newsitename': null},
      
      pumpslist: [],//[{"id":,"ip":,"cname":,"nname":,"admin":,"onreporter":,"onvhmi":}]
      pumps_in_reporter: [],
      site_health_profile: [],
      site_pumps_health: {},
      site_health_list:[]
    }),
    created() {
      //this.pumps_in_reporter = pumphooks.pumps_in_reporter;
      this.get_pumps_in_reporter = pumphooks.get_pumps_in_reporter;
      this.add_pump_to_vhmi = pumphooks.add_pump_to_vhmi;
      this.pumps_onvhmi_check = pumphooks.pumps_onvhmi_check;
      this.pumps_onreporter_check = pumphooks.pumps_onreporter_check;
      this.add_pump_to_reporter = pumphooks.add_pump_to_reporter;
      this.del_pump_from_reporter = pumphooks.del_pump_from_reporter;
    },
    mounted() {
      this.get_pumps_in_reporter();
    },  
    computed: {
      add_pumps_permission() {
        const useraccessdict = this.$store.getters.get_useraccessdict();
        return (useraccessdict != null) && useraccessdict["addpumps"];
      },
      view_health_link(){
        const useraccessdict = this.$store.getters.get_useraccessdict();
        return (useraccessdict != null) && useraccessdict["addpumps"];
      }
    },
    methods: {
      rebuild_pumpshealth_data() {
        const site_pumps_health = this.site_pumps_health;
        const instpumpsi = this.site_health_profile.indexOf("app.machine.multiplePumpManager.u8InstalledPumpCount");
        const enpumpsi = this.site_health_profile.indexOf("app.machine.multiplePumpManager.u8PumpEnableFlags");
        
        this.site_health_list = [];
        var site_health_list = [];
        this.pumpslist.forEach((pumpdetails) => {
          const pumpip = pumpdetails["ip"];
          var pumphealth = null;
          if (pumpip in site_pumps_health) {
            const total_installed_actuators = site_pumps_health[pumpip][instpumpsi];
            const enpumpsflags = site_pumps_health[pumpip][enpumpsi];
            var total_enabled_actuators = ( ((enpumpsflags & 0b10)>>1) + (enpumpsflags & 0b01) );
            const lastonline = site_pumps_health[pumpip][site_pumps_health[pumpip].length-1];
            pumphealth = {"inst":total_installed_actuators,"en":total_enabled_actuators,"off":false,"lastonline":lastonline};
          } else {
            pumphealth = {"inst":0,"en":0,"off":true,"lastonline":"N/A"};
          }
          site_health_list.push(pumphealth);
        });
        this.site_health_list = site_health_list;
      },
      fetch_pumps_health(pumpslist) {
        this.site_pumps_health = {};

        this.$api.post(this.$olsupervisorurl + '/health/aggregate/pumps',{"code":"get_pumps_health", "pumpslist":pumpslist})
        .then(response => {
          //console.log(response.data);
          if ((response.data["code"] == "get_pumps_health") && (response.data["success"] === true))
          {
            this.site_health_profile = response.data["varlist"];
            this.site_pumps_health = response.data["pumpshealth"];
          } else {
            this.site_health_profile = [];
            this.site_pumps_health = {};
          }
          this.rebuild_pumpshealth_data();
        });
      },
      fetch_pumps_details (recvdnode) {
        this.datafetch_in_progress = true;
        this.pumpslist = [];
        this.selectedNode = null;
        //console.log("test");

        this.$api.post(this.$orgmanurl + '/pumps/list',{"siteid":recvdnode.id, "recursive": this.isRecursiveFetch})
              .then(response => {
                //console.log(response.data);
                if (response.data["code"] == "list_company_pumps")
                {
                  this.pumpslist = response.data["company-pumps-list"];
                  //console.log(this.pumpslist);
                  this.selectedNode = recvdnode;

                  if (this.pumpslist != null) {
                    this.pumps_onvhmi_check();
                    this.pumps_onreporter_check();
                  }
                  else this.pumpslist = [];
                  this.fetch_pumps_health(this.pumpslist.map((pumpdetails)=>pumpdetails["ip"]));
                }
                //this.$emit('loadcomplete');
                this.datafetch_in_progress = false;
              });

        return;
      },
      addPumpClose(){
        this.addpump_error_msg = null;
        this.addPumpDialog = false;
      },
      addPumpConfirm(){
        const parent = this.selectedNode;
        console.log(parent);
        const newchilddata = {'code':'add_company_pump', "pid":parent.id, "pumpdata":this.addPumpData}
        console.log(newchilddata);
        this.$api.post(this.$orgmanurl + '/pumps/add', newchilddata)
                .then(response => {
                  if (response.data['code'] == 'add_company_pump') {
                    if (response.data['success'])
                    {
                      const newchildip = response.data['ip'];
                      const newchildid = response.data['id'];
                      const newchildnname = response.data['nname']; 
                      const newchildcname = response.data['cname']; 
                      const newchildpump = {"id": newchildid, "ip":newchildip, "nname":newchildnname,  "cname":newchildcname};
                      this.pumpslist.push(newchildpump);
                      this.addPumpDialog = false;
                    } else {
                      this.addpump_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.addpump_error_msg = "Invalid response!";
                  }
                });
      },
      renamePumpClick(idx){
        this.renPumpdx = idx;
        const pumpdata = this.pumpslist[this.renPumpdx];
        this.renPumpData['id'] = pumpdata['id'];
        this.renPumpData['ip'] = pumpdata['ip'];
        this.renPumpData['cname'] = pumpdata['cname'];
        this.renPumpData['nname'] = pumpdata['nname'];
        this.renpump_error_msg = null;
        this.renamePumpDialog = true;
      },
      renamePumpConfirm(){
        const newchilddata = {'code':'rename_company_pump', "id":this.renPumpData.id, "pumpdata":this.renPumpData}
        console.log(newchilddata);
        this.$api.post(this.$orgmanurl + '/pumps/rename', newchilddata)
                .then(response => {
                  if (response.data['code'] == 'rename_company_pump') {
                    if (response.data['success'])
                    {
                      this.pumpslist[this.renPumpdx]['cname'] = this.renPumpData['cname'];
                      this.pumpslist[this.renPumpdx]['nname'] = this.renPumpData['nname'];
                      this.renamePumpDialog = false;
                    } else {
                      this.renpump_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.renpump_error_msg = "Invalid response!";
                  }
                });
      },
      renamePumpClose(){
        this.renpump_error_msg = null;
        this.renamePumpDialog = false;
      },
      deletePumpClick(pumpidx){
        this.deletePumpIdx = pumpidx;
        this.delpump_error_msg = null;
        this.deletePumpDialog = true;
      },
      deletePumpConfirm(){
        this.$api.post(this.$orgmanurl + '/pumps/delete', {'code':'del_company_pump', "pumpid":this.pumpslist[this.deletePumpIdx]["id"]})
                .then(response => {
                  if (response.data['code'] == 'del_company_pump') {
                    if (response.data['success'])
                    {
                      this.pumpslist.splice(this.deletePumpIdx, 1);
                      this.deletePumpDialog = false;
                    } else {
                      this.delpump_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.delpump_error_msg = "Invalid response!";
                  }
                });
      },
      startDrag(evt, pumpid, pumpnname) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('id', pumpid);
        evt.dataTransfer.setData('name', pumpnname);
        //console.log("Dragging PumpID: " + pumpid);
      },
      onPumpDrop(pcdict) {
        this.selectedPumptoMove = pcdict;
        this.movePumptoOU_error_msg = ""; // Clear last error message
        this.movePumptoOUDialog = true;
      },
      moveConfirmClick(){
        /*console.log("MoveConfirmed!");
        console.log(this.selectedPumptoMove);*/
        const pumpid = this.selectedPumptoMove['id'];
        const newsiteid = this.selectedPumptoMove['newsiteid'];
        this.$api.post(this.$orgmanurl + '/pumps/move', {'code':'move_pump_company', "pumpid":pumpid, "newsiteid":newsiteid})
        .then(response => {
          //console.log(response.data);
          if (response.data['code'] == 'move_pump_company') {
            if (response.data['success'])
            {
              if (response.data['id'] === pumpid) {
                // refresh pumplist
                for(var idx=0;idx<this.pumpslist.length;idx++) {
                  if (this.pumpslist[idx]["id"] == pumpid) {
                    this.pumpslist.splice(idx,1);
                    break;
                  }
                }
                this.movePumptoOUDialog = false;
              } else {
                this.movePumptoOU_error_msg = "Invalid Pump Response!";
              }
            } else {
              this.movePumptoOU_error_msg = response.data['errmsg'];
            }
          } else {
            this.movePumptoOU_error_msg = "Invalid response!";
          }
        });
      },
      goto_eventssummary_page(ipaddr) {
        //console.log("goto_eventssummary_page", ipaddr);
        this.$router.push({ path: '/eventsum', query: { "pumpip": ipaddr } });
      },
      goto_pumphealth_page(ipaddr) {
        //console.log("goto_eventssummary_page", ipaddr);
        this.$router.push({ path: '/pumphealth', query: { "pumpip": ipaddr } });
      },
      email_health_report(siteid) {
        this.$api.post(this.$olsupervisorurl + '/email/launch',
          {"emailid": this.$store.getters.get_username(), "siteid":siteid}
        ).then(response => {
            //console.log(response.data);
            if (response.data["code"] == "email_health_report")
            {
              if (response.data["success"] === true){
                console.log("email_health_report Success");
              } else {
                alert(response.data["errmsg"]);
              }
            }
          });
      }
    }
  }
</script>
<style scoped>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0px;
}
@media (min-width:960px){
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td>a.smart-collapse:nth-of-type(1) {
    display:inline-block;
    width:8em;
  }
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td>a.smart-collapse:nth-of-type(2) {
    display:none;
  }
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td>span.smart-collapse {
    display:none;
  }
}
@media (max-width:960px){
  .auto-collapse{
    display:none;
  }
}
@media (max-width:960px){
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td>a.smart-collapse:nth-of-type(1) {
    display:none;
  }
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td>a.smart-collapse:nth-of-type(2) {
    display:inline-block;
    width:8em;
  }
}
</style>