<template>
<div id="pumpsonline" style="height:86vh">
    <v-toolbar class="mt-2" flat>
      <v-toolbar-title>Online Pumps ({{ num_found_pumps }})</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click.prevent="toggleMap">Toggle Map</v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    
    <LoadingBar v-if="datafetch_in_progress" />
    <v-container v-else fluid class="fill-height">
    <v-row class="fill-height" justify="space-between" >
      <v-col cols="12" class="fill-height">
        <v-card v-if="mapdialog" class="fill-height" cols="9">
          <AzureMap class="AzureMap" :zoom="1">
            <AzureMapZoomControl />
            <AzureMapDataSource>
               <AzureMapSymbolLayer :options="symbolLayerOptions" @mousedown="onMouseDown"/>

                <template v-for="pump,idx in pumpslist">
                  <!-- Add Points to the Data Source -->
                  <AzureMapPoint :key="`MapPoint-${idx}`" :properties="pump" 
                    :longitude="pump['longitude']" :latitude="pump['latitude']" />
                  <!-- Add a Popup to the map for every Point -->
                  <AzureMapPopup :key="`Popup-${idx}`" v-model="pump.isPopupOpen"
                    :position="[pump['longitude'], pump['latitude']]"
                    :pixel-offset="[0, -18]" :close-button="true" class="AzureMapPopup" >
                      <h3>{{ pump['nname'] }}</h3>
                      <p>
                        Customer: {{ pump['cname'] }}<br/>
                        ESP-CM: {{ pump['espcmser'] }}<br/>
                        Router: {{ pump['routerlabel'] }}<br/>
                        Location: {{ pump['location'] }}<br/>
                        LastOnline: {{ pump['lastonline'] }}<br/>
                      </p>
                  </AzureMapPopup>
                </template>
            </AzureMapDataSource>
          </AzureMap>
        </v-card>
        <v-card v-else class="fill-height" cols="9" style="overflow-y: scroll">
        <v-card-title>
          <v-btn color="primary" @click.prevent="refresh_pumpslist">Refresh</v-btn>
          <v-spacer></v-spacer>
          <v-text-field v-model="searchtext" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        </v-card-title>

        <v-scroll-y-transition mode="out-in">
            <v-data-table :headers="headers" :items="pumpslist" :items-per-page="15"  :search="searchtext"
              must-sort :sort-by.sync="sortPumpsBy" :sort-desc.sync="sortPumpsDesc">
            <template v-slot:item.vhmi="{ item }">
              <template  v-if="item.id != null">
                <v-btn v-if="item.onvhmi" dark small color="error">
                  <v-icon light > mdi-checkbox-marked-outline </v-icon>
                </v-btn>
                <v-btn v-else dark small color="primary" @click="add_pump_to_vhmi(item.id,item.ip)">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
            </template>
            <template #item.reporter="{ item }">
              <v-btn v-if="item.onreporter" dark small color="error" @click="del_pump_from_reporter(item.ip)">
                <v-icon light > mdi-minus </v-icon>
              </v-btn>
              <v-btn v-else dark small color="primary" @click="add_pump_to_reporter(item.id,item.ip)">
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </template>
            
          </v-data-table>
        </v-scroll-y-transition>
  </v-card>
      </v-col>
    </v-row>
    </v-container>
</div>
</template>

<script>
  import dateutils from '../plugins/date_utilities';
  import pumphooks from '../plugins/pump_data_hooks';
  import LoadingBar from "../components/LoadingBar";

  import Vue from 'vue'
  import VueAzureMaps from 'vue-azure-maps'
  Vue.use(VueAzureMaps, {
    key: 'z_J8-o-wDXkdbamCk_DtB4MIEY5nrPvVWJ6tn2aVrpI',
  })

  import { AzureMap, AzureMapDataSource, AzureMapZoomControl,AzureMapPopup,AzureMapSymbolLayer,
   AzureMapPoint } from 'vue-azure-maps'

  export default {
    name: "PumpsOnlinePage",

    components: {
      AzureMap,AzureMapZoomControl,AzureMapSymbolLayer,
      AzureMapDataSource,
      AzureMapPoint,AzureMapPopup,
      LoadingBar
    },
    data: () => ({
      pumpslist: [],      
      datafetch_in_progress: false,
      
      pumps_in_reporter : [],

        headers: [
          { text: 'Add to VHMI', value: 'vhmi', filterable: false, sortable: false },
          { text: 'Add to Reporter', value: 'reporter', filterable: false, sortable: false },
          { text: 'IP Address', value: 'espcmip', filterable: true, sortable: true },
          //{ text: 'ESPCM-Label', value: 'espcmlabel', filterable: true, sortable: true },
          //{ text: 'ESPCM-S/N', value: 'espcmser', filterable: false, sortable: true },
          { text: 'Pump-S/N', value: 'pumpser', filterable: true, sortable: true },
          { text: 'FW Version', value: 'espcmfw', filterable: false, sortable: true },
          { text: 'Nickname', value: 'nname', filterable: false, sortable: true },
          { text: 'Customer', value: 'cname', filterable: false, sortable: true },
          { text: 'Last Online', value: 'lastonline', filterable: false, sortable: true },
          //{ text: 'RouterIP', value: 'routerip', filterable: true, sortable: true },
          { text: 'RouterLabel', value: 'routerlabel', filterable: true, sortable: true },
          //{ text: 'Global IP', value: 'globalip', filterable: false, sortable: false },
          { text: 'Location', value: 'location', filterable: false, sortable: false },
        ],
        sortPumpsBy: 'lastonline', sortPumpsDesc: true,
      searchtext: "",

      mapdialog: false,
      symbolLayerOptions: {
        iconOptions: {
          ignorePlacement: true,
          allowOverlap: true,
          image: 'pin-red',
        },
      }
    }),
    created() {
      this.convertUTCDateToSortableLocalDate = dateutils.convertUTCDateToSortableLocalDate;

      //this.pumps_in_reporter = pumphooks.pumps_in_reporter;
      this.get_pumps_in_reporter = pumphooks.get_pumps_in_reporter;
      this.add_pump_to_vhmi = pumphooks.add_pump_to_vhmi;
      this.pumps_onvhmi_check = pumphooks.pumps_onvhmi_check;
      this.pumps_onreporter_check = pumphooks.pumps_onreporter_check;
      this.add_pump_to_reporter = pumphooks.add_pump_to_reporter;
      this.del_pump_from_reporter = pumphooks.del_pump_from_reporter;
      this.goto_pumpreporter_varspage = pumphooks.goto_pumpreporter_varspage;
    },
    mounted() {
      this.get_pumps_in_reporter();
      this.get_pumps_online();
    },
    computed: {
      num_found_pumps() {
        return this.pumpslist.length;
      }
    },
    methods: {
      refresh_pumpslist(){
        this.get_pumps_online();
      },
      get_pumps_online() {
        this.pumpslist=[];
        this.datafetch_in_progress = true;
        //console.log("get_pumps_online - start - ", new Date());
        this.$api.post(this.$olsupervisorurl + '/listpumps')
        .then(response => {
          //console.log(response.data);
          if (response.data["code"] == "list_online_pumps")
          {
            if (response.data["success"]) {
              const onlinepumps = response.data["pumplist"];
              //var pumpslist = [];
              for(var idx in onlinepumps){
                const pumpdict = onlinepumps[idx];
                pumpdict["longitude"] = parseFloat(pumpdict["longitude"]);
                pumpdict["latitude"] = parseFloat(pumpdict["latitude"]);
                pumpdict["lastonline"] = this.convertUTCDateToSortableLocalDate(pumpdict["lastonline"]);
                //pumpslist.push(pumpdict);
              }
              this.pumpslist=onlinepumps;
              //console.log(pumpslist);

              if (this.pumpslist != null) {
                this.pumps_onvhmi_check();
                this.pumps_onreporter_check();
              }
              else this.pumpslist = [];
              //console.log("get_pumps_online - received - ", new Date());
            } else {
              alert(response.data['errmsg']);
            }
          } else {
              alert("Invalid response!");
          }
          //this.$emit('loadcomplete');
          this.datafetch_in_progress = false;
        });
      },
      toggleMap(){
        this.mapdialog = !this.mapdialog;
        //console.log("Map Toggled", this.mapdialog);
      },
      onMouseEnter(e) {
        console.log("onMouseEnter", e);
      },
      onMouseLeave(e) {
        console.log("onMouseLeave", e);
      },
      onMouseDown(e) {
        //console.log("onMouseDown", e);
        if (e.shapes && e.shapes.length > 0) {
          // Capture the selected shape.
          const selectedShape = e.shapes[0]
          // Check if the point is in our data
          const selectedpump = this.pumpslist.find((p) => p.espcmip === selectedShape.data.properties.espcmip);
          //console.log(selectedpump)
          if (selectedpump) {
            // Capture the selected point.
            //this.selectedPoint = selectedpumpselectedpump
            //console.log(selectedpump);
            // Show the popup
            Vue.set(selectedpump, "isPopupOpen", true);
            //selectedpump.isPopupOpen = true;
          }
        }
      },
    }
  }
</script>

<style scoped>
.AzureMap {
  width: 100%;
  height: 100%;
}
.AzureMapPopup {
  max-width: 240px;
  padding: 1rem;
}
</style>