<template>
<v-container>
    <v-row>
        <v-col>
            <p class="text-center">PumpState:&nbsp;<b>{{ represent_pump_state(pump) }}</b></p>
            <template v-if="pump['daq'].length > 0">
                <div class="d-none d-md-block">
                    <v-img src="@/assets/monitoring.png"  ></v-img>
                    <div id="p1ntc1">{{ pump["daq"][p1ntc1i].toFixed(0) }}</div>
                    <div id="p1ntc2">{{ pump["daq"][p1ntc2i].toFixed(0) }}</div>
                    <div id="p1ntc3">{{ pump["daq"][p1ntc3i].toFixed(0) }}</div>
                    <div id="p1espcm">{{ pump["daq"][p1espcmi].toFixed(0) }}</div>
                    <div id="p1ntc4">{{ pump["daq"][p1ntc4i].toFixed(0) }}</div>
                    <div id="p1drive">{{ pump["daq"][p1drivei].toFixed(0) }}</div>

                    <template v-if="pumpIsDual">
                        <v-img src="@/assets/monitoring_pump2.png"  ></v-img>
                        <div id="p2ntc1">{{ pump["daq"][p2ntc1i].toFixed(0) }}</div>
                        <div id="p2ntc2">{{ pump["daq"][p2ntc2i].toFixed(0) }}</div>
                        <div id="p2ntc3">{{ pump["daq"][p2ntc3i].toFixed(0) }}</div>
                        <div id="p2espcm">{{ pump["daq"][p2espcmi].toFixed(0) }}</div>
                        <div id="p2ntc4">{{ pump["daq"][p2ntc4i].toFixed(0) }}</div>
                        <div id="p2drive">{{ pump["daq"][p2drivei].toFixed(0) }}</div>
                    </template>
                </div>
                <v-container class="d-block d-md-none">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr><th class="text-center text-h5" colspan="2">Actuator 1</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Left Temperature</td>
                                            <td>{{ pump["daq"][p1ntc1i].toFixed() }} °C</td>
                                        </tr>
                                        <tr>
                                            <td>Center Temperature</td>
                                            <td>{{ pump["daq"][p1ntc2i].toFixed() }} °C</td>
                                        </tr>
                                        <tr>
                                            <td>Right Temperature</td>
                                            <td>{{ pump["daq"][p1ntc3i].toFixed() }} °C</td>
                                        </tr>
                                        <tr>
                                            <td>Regen Temperatrue</td>
                                            <td>{{ pump["daq"][p1ntc4i].toFixed() }} °C</td>
                                        </tr>
                                        <tr>
                                            <td>Drive Temperature</td>
                                            <td>{{ pump["daq"][p1drivei].toFixed() }} °C</td>
                                        </tr>
                                        <tr>
                                            <td>ESP-CM Temperature</td>
                                            <td>{{ pump["daq"][p1espcmi].toFixed() }} °C</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                        <v-col v-if="pumpIsDual" cols="12" sm="6">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr><th class="text-center text-h5" colspan="2">Actuator 2</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Left Temperature</td>
                                            <td>{{ pump["daq"][p2ntc1i].toFixed() }} °C</td>
                                        </tr>
                                        <tr>
                                            <td>Center Temperature</td>
                                            <td>{{ pump["daq"][p2ntc2i].toFixed() }} °C</td>
                                        </tr>
                                        <tr>
                                            <td>Right Temperature</td>
                                            <td>{{ pump["daq"][p2ntc3i].toFixed() }} °C</td>
                                        </tr>
                                        <tr>
                                            <td>Regen Temperature</td>
                                            <td>{{ pump["daq"][p2ntc4i].toFixed() }} °C</td>
                                        </tr>
                                        <tr>
                                            <td>Drive Temperature</td>
                                            <td>{{ pump["daq"][p2drivei].toFixed() }} °C</td>
                                        </tr>
                                        <tr>
                                            <td>ESP-SM Temperature</td>
                                            <td>{{ pump["daq"][p2espcmi].toFixed() }} °C</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import vhmi_mcdvars_list from '../../plugins/mcdvariables';
import vhmiutils from '../../plugins/vhmi_utilities';

export default {
  name: "MonitorTab",
  props: {
      pump: null
  },
  data(){
      return {
          u16hmistatei: null,
          instpumpsi: null,

          p1ntc1i:null, p1ntc2i:null, p1ntc3i:null, p1ntc4i:null,
          p2ntc1i:null, p2ntc2i:null, p2ntc3i:null, p2ntc4i:null,

          p1espcmi:null, p2espcmi:null,
          p1drivei:null, p2drivei:null
      }
  },
  computed: {
      pumpIsDual() {
          return (this.pump["daq"][this.instpumpsi] == 2);
      }
  },
  created(){
    this.enum_pump_states = vhmiutils.enum_pump_states;
    this.represent_pump_state = vhmiutils.represent_pump_state;
    this.u16hmistatei = vhmi_mcdvars_list.indexOf("app.hmiViewModel.u16StateHMI");
    this.instpumpsi = vhmi_mcdvars_list.indexOf("app.machine.multiplePumpManager.u8InstalledPumpCount");

    this.p1ntc1i = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.pumpThermalSupervisor.NTC1.s16TempSense_Deg");
    this.p1ntc2i = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.pumpThermalSupervisor.NTC2.s16TempSense_Deg");
    this.p1ntc3i = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.pumpThermalSupervisor.NTC3.s16TempSense_Deg");
    this.p1ntc4i = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.pumpThermalSupervisor.NTC4.s16TempSense_Deg");
    this.p1espcmi = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.pumpThermalSupervisor.OnBoardNTC.s16TempSense_Deg");
    this.p1drivei = vhmi_mcdvars_list.indexOf("app.machine.pump1.driveController.driveA1000.stDriveStateFeedback.s16HeatsinkTemperature");

    this.p2ntc1i = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.pumpThermalSupervisor.NTC1.s16TempSense_Deg");
    this.p2ntc2i = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.pumpThermalSupervisor.NTC2.s16TempSense_Deg");
    this.p2ntc3i = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.pumpThermalSupervisor.NTC3.s16TempSense_Deg");
    this.p2ntc4i = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.pumpThermalSupervisor.NTC4.s16TempSense_Deg");
    this.p2espcmi = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.pumpThermalSupervisor.OnBoardNTC.s16TempSense_Deg");
    this.p2drivei = vhmi_mcdvars_list.indexOf("app.machine.pump2.driveController.driveA1000.stDriveStateFeedback.s16HeatsinkTemperature");
  }
}
</script>

<style scoped>
#p1ntc1  { position:absolute; top:141px; left:285px; font-size: 21px; }
#p1ntc2  { position:absolute; top:141px; left:369px; font-size: 21px; }
#p1ntc3  { position:absolute; top:141px; left:453px; font-size: 21px; }
#p1espcm { position:absolute; top:270px; left:120px; font-size: 21px; }
#p1ntc4  { position:absolute; top:270px; left:414px; font-size: 21px; }
#p1drive { position:absolute; top:270px; left:711px; font-size: 21px; }

#p2ntc1  { position:absolute; top:399px; left:285px; font-size: 21px; }
#p2ntc2  { position:absolute; top:399px; left:369px; font-size: 21px; }
#p2ntc3  { position:absolute; top:399px; left:453px; font-size: 21px; }
#p2espcm { position:absolute; top:525px; left:120px; font-size: 21px; }
#p2ntc4  { position:absolute; top:525px; left:414px; font-size: 21px; }
#p2drive { position:absolute; top:525px; left:711px; font-size: 21px; }
</style>